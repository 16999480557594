import React from "react"
import LayoutNew from "../../components/LayoutNew"
import { Helmet } from "react-helmet"
import { StaticImage } from "gatsby-plugin-image"

export default function TermsOfUse() {
  return (
    <LayoutNew>
      <Helmet>
        {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
        <title>Why Purple Cow has the best team 💜🐄</title>
      </Helmet>
      <section className="px-4 py-8 mx-auto font-light prose prose-lg">
       
        
        <StaticImage
          layout="constrained"
          src="images/Why-Purple-Cow-has-the-best-team.png"
          alt="Purple Cow Internet - Picking the right plan"
          /> 
            <p>At Purple Cow Internet, we take pride in our exceptional team that drives our success. Our fully remote workforce spans from rural Nova Scotia to international locales like Mexico, France, and Italy, enabling us to tap into a diverse talent pool. This global perspective is crucial as we strive to make high-quality internet access more affordable and reliable.</p>

            <p>Our unique approach to remote work not only allows flexibility but also encourages our team members to explore the world, which we believe enhances their creativity and work output. Whether they are in Halifax or on the move, our team remains connected and responsive, ensuring top-notch service that our customers have come to expect.</p>

            <p>New hires at Purple Cow receive extensive training, especially those in customer-facing roles, to ensure they're well-prepared to meet our customers' needs. We combine technical knowledge with a touch of humor to make our customer interactions pleasant and memorable. We also offer competitive pay packages that adjust with changes in the minimum wage, reflecting economic trends and providing fair compensation.</p>

            <p>Our team's effectiveness in providing excellent customer service is reflected in the thousands of positive reviews we receive. Customers praise our responsive service and reliable internet connection:</p>
            <ul>
                <li><strong>Dominique Brennan</strong> appreciated our "great customer service and reasonable pricing."</li>
                <li><strong>Sarah Goudey</strong> noted our swift response when she needed a coax line replaced, highlighting our "stellar customer service."</li>
                <li><strong>Shandi Davis</strong> recommended switching to Purple Cow for "affordable but still great speed" internet, emphasizing the smooth setup process.</li>
            </ul>

            <p>As we continue to grow, currently with just under 50 team members, we're always looking to expand our team with individuals who value flexibility, innovation, and are driven by the mission to transform internet service across the Maritimes.</p>

            <p>Join us at Purple Cow Internet, where we're not just an ISP but a community of forward-thinking professionals dedicated to improving how internet services are delivered. Be part of our journey to enhance internet connectivity one connection at a time, across the Maritimes and beyond.</p>

            
            Bradley Farquhar<br></br>
            CEO<br></br>
            <br></br><br></br>

      </section>
    </LayoutNew>
  )
}
